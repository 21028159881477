<template>
  <nav
    aria-label="breadcrumb"
    class="breadcrumb"
  >
    <ul>
      <li
        v-for="(crumb, idx) in crumbs"
        :key="idx"
        :data-testid="`crumb-${crumb.name}`"
      >
        <nuxt-link
          v-if="crumb.link !== ''"
          :to="crumb.link"
        >
          {{ crumb.name }}
        </nuxt-link>

        <span v-else>{{ crumb.name }} </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    crumbs: {
      type: Array,
      default: () => [],
    }
  },
};
</script>

<style scoped>
.breadcrumb {
  width: 100%;
  min-width: 100%;
  background: #f5f5f5;
  min-height: 47px;
}
.breadcrumb ul {
  line-height: 47px;
  padding: 0 10px 0 188px;
  list-style: none;
  left: 0;
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
}
.breadcrumb li:first-child {
  padding: 0;
  background: none;
  margin-left: 15px;
}
.breadcrumb li {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #111;
  position: relative;
  padding-left: 45px;
}
.breadcrumb li a {
  color: #111;
  text-decoration: none;
}
.breadcrumb li a:hover {
  text-decoration: underline;
}
.breadcrumb ul > li + li:before {
  position: absolute;
  left: 16px;
  width: 24px;
  height: 18px;
  color: #111;
  content: '//';
  font-size: 14px;
  line-height: 18px;
}
@media only screen and (max-width: 1000px) {
  .breadcrumb ul {
    text-align: center;
    padding: 15px 10px;
  }
  .breadcrumb li:first-child {
    margin-left: 0;
  }
}
</style>
